<template>
  <div>
    <Navbar page="Plano de ação integral (PAI)" link="/entregaveis" nameLink="Entregáveis" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div class="flex justify-end">
                <a 
                  :href="`${url_api}/v1/diagnosticoAplicado/exportar/relatorioExcel?token=${$store.state.token}&&empresa=${this.$store.state.empresa._id}&&nome=${this.$store.state.empresa.nome || ''}`"
                  rel="noreferrer" 
                  class="w-full md:w-auto lg:w-auto bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 focus:shadow-sm focus:ring-4 text-white flex gap-2 py-2 items-end px-8 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center"
                >
                    <svg  class=" h-6 w-6" xmlns="http://www.w3.org/2000/svg" :fill="invertColor($store.state.colorPrincipal, true)" viewBox="0 0 256 256">
                      <path d="M208,28H72A28,28,0,0,0,44,56V224a4,4,0,0,0,4,4H192a4,4,0,0,0,0-8H52v-4a20,20,0,0,1,20-20H208a4,4,0,0,0,4-4V32A4,4,0,0,0,208,28Zm-4,160H72a27.94,27.94,0,0,0-20,8.42V56A20,20,0,0,1,72,36H204Z">
                      </path>
                    </svg>
                    Baixar PAI
                </a>
            </div>
      <div class="grid grid-cols-12 gap-6 mb-3">

        <section class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select 
            v-model="filtro.grauDeRisco" 
            name="filtrograuDeRisco" 
            id="filtrograuDeRisco" 
            class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
          >
              <option default :value="null">Filtrar por grau de risco</option>  
              <option v-for="(grau, idx) in grausDeRiscos" :value="grau" :key="idx">{{ grau }}</option>
          </select>
        </section>

        <section class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select 
            v-model="filtro.status" 
            name="filtroStatus" 
            id="filtroStatus" 
            class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
          >
              <option default :value="null">Filtrar por status</option>  
              <option v-for="(status, idx) in statusPossiveis" :value="status" :key="idx">{{ status }}</option>
          </select>
        </section>

        <section class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </section>

      </div>
      <div class="flex flex-col gap-2">
            <div class="shadow border-b border-gray-200 overflow-x-auto">
              <table class="divide-y divide-gray-200 border-spacing-4">
                <thead class="bg-gray-50">
                  <tr class="text-xs font-medium space-x-2 space-y-3 text-gray-500 text-center">
                    <th>
                      Relatório de origem
                    </th>
                    <th>
                      Setor responsável / Quem respondeu
                    </th>
                    <th>
                      Tema / Nome / Processo / Atividade
                    </th>
                    <th>
                      Problema / Vulnerabilidade
                    </th>
                    <th>
                      Grau de risco
                    </th>
                    <th>
                      Conduta a ser implementada
                    </th>
                    <th>
                      Conduta aprovada?
                    </th>
                    <th>
                      Observações
                    </th>
                    <th>
                      Responsável(eis) pela execução da conduta
                    </th>
                    <th>
                      Prazo inicial
                    </th>
                    <th>
                      Prazo Final
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Data de conclusão
                    </th>
                    <th>
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(item, index) in list" :key="index" 
                    class="text-xs text-center odd:bg-white even:bg-slate-100 text-gray-500"
                  >
                    <td class="p-2">
                        Diagnóstico {{ item.tipoDiagnostico }}
                    </td>
                    <td class="px-2">
                        <span v-if="item.diagAplicado.destinatarioAgente">
                          Agente: <br><b>{{ item.diagAplicado.destinatarioAgente.nome }}</b>
                        </span>
                        <span v-if="item.diagAplicado.destinatarioPessoa">
                          Pessoa: <br><b><span>{{ item.diagAplicado.destinatarioPessoa.setor ? item.diagAplicado.destinatarioPessoa.setor.nome : "Sem setor" }}</span> / {{ item.diagAplicado.destinatarioPessoa.nome }}</b>
                        </span>
                    </td>
                    <td>
                        {{ item.tema }}
                    </td>
                    <td class="w-72">
                        <span 
                          v-for="(Vulnerabilidade, idx) in item.pergunta.vulnerabilidades" 
                          :key="`vulnerabilidade-${idx}`">
                         {{ Vulnerabilidade }}
                        </span>
                    </td>
                    <td class="w-16">
                        <span :style="`color: ${statusGrauDeRisco(item.pergunta.pai.grauDerisco)}`">
                          {{ item.pergunta.pai.grauDerisco }}
                        </span>
                    </td>

                    <td class="w-72">
                        <span
                          v-for="(conduta, idx) in item.pergunta.respostaNao" 
                          :key="`conduta-${idx}`">
                          {{ conduta }}
                        </span>
                    </td>

                    <td>
                        {{ item.pergunta.pai.condutaAprovada }} |
                        <span v-if="item.pergunta.pai.quemAprovou">{{ item.pergunta.pai.quemAprovou }}</span>
                    </td>

                    <td>
                        {{ item.pergunta.pai.obs }}
                    </td>

                    <td class="divide-y divide-gray-200">
                        <span 
                          v-for="responsavel in item.pergunta.pai.responsaveisPelaConduta" 
                          :key="responsavel._id">
                          {{ responsavel.nome }} <br>
                        </span>
                    </td>

                    <td>
                        <span v-if="item.pergunta.pai.prazoDeExecucao.de">
                          {{ item.pergunta.pai.prazoDeExecucao.de | moment("DD/MM/YYYY HH:mm") }}
                        </span>
                    </td>
                    <td>
                        <span v-if="item.pergunta.pai.prazoDeExecucao.ate">
                          {{ item.pergunta.pai.prazoDeExecucao.ate | moment("DD/MM/YYYY HH:mm") }}
                        </span>
                    </td>

                    <td>
                        {{ item.pergunta.pai.status }}
                    </td>           
                    <td>
                        <span v-if="item.pergunta.pai.concluidoEm">
                          {{ item.pergunta.pai.concluidoEm  | moment("DD/MM/YYYY HH:mm")}}
                        </span>
                    </td>
                    <td class="p-2">
                      <router-link 
                        :to="`/diagnosticos/visualizar/${item.controle}/revisar/${item._id}`" 
                        type="button" 
                        class="text-center rounded-sm bg-gray-600 text-white px-1"
                      >
                          Revisar
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <label for="perPage">Itens por página

              <select class="w-20 mt-2 mr-1 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" @change="start" v-model="perPage">
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>

            </label>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      list: [],
      page: 1,
      perPage: 20,
      desativadas: false,
      total: 0,
      filtro: {
        tipoDiagnostico: null,
        status: null,
        grauDeRisco: null,
      },
      grausDeRiscos: ['Muito baixo', 'Baixo', 'Médio', 'Alto', 'Muito Alto'],
      statusPossiveis: ['Pendente', 'Em andamento', 'Concluído'],//Pendente, Em andamento, Concluído
      query: { page: 1, limit: 10, skip: 0, desativadas: false },
    }
  },
  methods: {
    updateFiltro(){
      this.query.page = 1;
      this.query.limit = 10;
      this.query.skip = 0;

      this.query = { 
        page: this.query.page, 
        limit: this.query.limit, 
        skip: this.query.skip, 
        filtro: JSON.stringify(this.filtro),
        t: moment().format('x'),
      };

      this.$router.push({ path: `/entregaveis/pai`, query: this.query });
    },
    statusGrauDeRisco(status){
      if(status === 'Muito baixo') return '#3a9669';
      if(status === 'Baixo') return '#1b4e3b';
      if(status === 'Médio') return '#f29e0c';
      if(status === 'Alto') return '#aa1a1a';
      if(status === 'Muito Alto') return '#ff2626';
    },
    async start() {

      this.query.limit = this.perPage;

      await this.$http.post(`/v1/diagnosticoAplicado/perguntas/vinculo-pai`, {
        filtro: this.filtro,
        skip: this.query.skip, 
        limit: this.query.limit,
      })
      .then((resp)=>  {
        this.list = resp.data.data
        this.total = resp.data.total
      })
    },

    async setPage(page) {
      console.log("SET PAGE");
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;

      this.$router.push({ path: `/entregaveis/pai`, query: this.query });
    },

  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
}
</script>